<template>
  <RightNavDiscussion
    :selectedCategoryName="selectedCategoryName"
    :isLoadingFlag="isLoadingPostListFlag"
    @newPostAdded="getListOfPosts()"
  />
</template>

<script>
import RightNavDiscussion from "@/components/SelfPaced/Discussion/RightNavDiscussion.vue";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import { mapGetters } from "vuex";
export default {
  components: { RightNavDiscussion },
  mixins: [discussionViewSelectorMixin],
  props: {
    selectedCategoryName: Object
  },
  computed: {
    ...mapGetters(["getTopicId"])
  }
};
</script>
