var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forums__list--container",attrs:{"id":"forumsListContainer"}},[_c('div',{staticClass:"forums__search--container",class:{
      'hide-container': _vm.getDiscussionPosts.results.length === 0,
      'show-container': _vm.searchPost || _vm.unreadFilter || _vm.dropdownValue2?.label
    }},[_c('div',{staticClass:"search-top",class:{ 'search-active': _vm.getIsSearchActive },attrs:{"id":"search-bar"}},[(_vm.getIsSearchActive)?_c('div',{staticClass:"search-back",on:{"click":_vm.resetSearch}},[_c('img',{attrs:{"src":_vm.backIcon,"alt":"back"}})]):_c('div',{staticClass:"forums-categories"},[_c('span',{staticClass:"main-category"},[_vm._v(" "+_vm._s(_vm.getTopicName.categoryName)+" ")]),(_vm.getTopicName.subCategoryName)?_c('span',{staticClass:"sub-category"},[_vm._v(" "+_vm._s(_vm.getTopicName.subCategoryName)+" ")]):_vm._e()]),_c('div',{staticClass:"forums__search--bar"},[_c('img',{attrs:{"src":require('@/assets/images/vle/forums/features/search.svg'),"alt":"search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchPost),expression:"searchPost"}],staticClass:"form-control",attrs:{"type":"text","maxlength":60,"aria-label":"Search","placeholder":_vm.$t('general.search')},domProps:{"value":(_vm.searchPost)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchPost=$event.target.value},function($event){return _vm.handleChange()}],"focus":_vm.setSearchActive}})])]),_c('div',{staticClass:"search-bottom"},[_c('div',{staticClass:"result-count"},[_vm._v(" "+_vm._s(_vm.getDiscussionPosts.count)+" "+_vm._s(_vm.$t("discussions.post.results_available"))+" ")]),_c('div',{staticClass:"post-filter"},[_c('div',{class:[
            'select-category select-filter-item',
            {
              'select__category--active': _vm.isDropdownOpen,
              'category-selected': _vm.unreadFilter || _vm.flaggedFilter
            }
          ],attrs:{"id":"select-filter","aria-label":_vm.$t('discussions.post.filter_by')},on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"selector",on:{"mouseover":function($event){_vm.isDropdownHovered = true},"mouseout":function($event){_vm.isDropdownHovered = false}}},[_c('span',{staticClass:"label"},[(!_vm.unreadFilter && !_vm.flaggedFilter)?_c('img',{staticClass:"image",attrs:{"src":_vm.isDropdownHovered || _vm.isDropdownOpen
                    ? _vm.filterHovered
                    : _vm.filterDefault,"alt":"filter"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("discussions.post.filter_by"))+" "),(_vm.unreadFilter || _vm.flaggedFilter)?_c('span',{staticClass:"filter-count"},[_vm._v(" "+_vm._s(_vm.unreadFilter && _vm.flaggedFilter ? "2" : "1")+" ")]):_vm._e()]),(_vm.unreadFilter || _vm.flaggedFilter)?_c('img',{staticClass:"remove",attrs:{"src":_vm.removeIcon,"alt":"remove"},on:{"click":function($event){return _vm.removeValue('filter')}}}):_c('img',{style:({
                transform: _vm.isDropdownOpen ? 'rotate(180deg)' : 'none'
              }),attrs:{"src":_vm.isDropdownHovered || _vm.isDropdownOpen
                  ? _vm.arrowHovered
                  : _vm.arrowDefault,"alt":"arrow"}})]),_c('div',{staticClass:"select-item filter__select--item",class:{ 'hide-dropdown': !_vm.isDropdownOpen }},[_c('ul',{staticClass:"select__item--inner"},[_c('li',{staticClass:"filter-item",class:{ active: _vm.unreadFilter }},[_c('label',{attrs:{"for":"unreadFilter"}},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.unread"))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unreadFilter),expression:"unreadFilter"}],staticClass:"filter-checkbox",attrs:{"id":"unreadFilter","type":"checkbox","name":"unreadFilter"},domProps:{"checked":Array.isArray(_vm.unreadFilter)?_vm._i(_vm.unreadFilter,null)>-1:(_vm.unreadFilter)},on:{"change":function($event){var $$a=_vm.unreadFilter,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.unreadFilter=$$a.concat([$$v]))}else{$$i>-1&&(_vm.unreadFilter=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.unreadFilter=$$c}}}}),_c('img',{staticClass:"checkmark",attrs:{"src":_vm.checkboxChecked,"alt":"checked"}})])])])])]),_c('div',{staticClass:"select-category select__sort--item",class:{
            'select__category--active': _vm.isDropdownOpen2,
            'category-selected': _vm.dropdownValue2?.label
          },attrs:{"id":"select-category","aria-label":_vm.$t('discussions.post.sort_by')},on:{"click":_vm.toggleDropdown2}},[_c('div',{staticClass:"selector",class:{ active: _vm.dropdownValue2?.label },on:{"mouseover":function($event){_vm.isDropdownHovered2 = true},"mouseout":function($event){_vm.isDropdownHovered2 = false}}},[_c('span',{staticClass:"label"},[(!_vm.dropdownValue2?.label)?_c('img',{staticClass:"image",attrs:{"src":_vm.isDropdownHovered2 || _vm.isDropdownOpen2
                    ? _vm.sortHovered
                    : _vm.sortDefault,"alt":"sorting"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("discussions.post.sort_by"))+" "),(_vm.dropdownValue2?.label)?_c('span',[_vm._v(_vm._s(_vm.dropdownValue2?.label))]):_vm._e()]),(_vm.dropdownValue2?.label)?_c('img',{staticClass:"remove",attrs:{"src":_vm.removeIcon,"alt":"remove"},on:{"click":function($event){return _vm.removeValue('sort')}}}):_c('img',{style:({
                transform: _vm.isDropdownOpen2 ? 'rotate(180deg)' : 'none'
              }),attrs:{"src":_vm.isDropdownHovered2 || _vm.isDropdownOpen2
                  ? _vm.arrowHovered
                  : _vm.arrowDefault,"alt":"arrow"}})]),(_vm.isDropdownOpen2)?_c('div',{staticClass:"select-item"},[_c('ul',{staticClass:"select__item--inner"},_vm._l((_vm.sortItems),function(item,index){return _c('li',{key:index,staticClass:"clickable-category",class:{ current: item.value === _vm.dropdownValue2?.value },on:{"click":function($event){return _vm.selectDropdownItem2(item)}}},[_c('span',[_vm._v(_vm._s(item.label))]),(item.value === _vm.dropdownValue2?.value)?_c('img',{attrs:{"src":_vm.checkIcon,"alt":"tick"}}):_vm._e()])}),0)]):_vm._e()])])])]),(
      _vm.getDraftListIsLoading &&
        !_vm.getTopicId &&
        !_vm.searchPost &&
        !_vm.unreadFilter &&
        !_vm.dropdownValue2?.label
    )?_c('div',{staticClass:"shimmer-container"},[_c('shimmer',{attrs:{"variant":"discussion-forums-post"}})],1):[(
        _vm.getDiscussionDraftPosts.results.length > 0 &&
          !_vm.getTopicId &&
          !_vm.searchPost &&
          !_vm.unreadFilter &&
          !_vm.dropdownValue2?.label
      )?_c('VueSlickCarousel',_vm._b({staticClass:"slick__slider--container draft__post--slider"},'VueSlickCarousel',_vm.draftPostListOptions,false),_vm._l((_vm.getDiscussionDraftPosts.results),function(c,index){return _c('div',{key:index,staticClass:"spadding-container"},[_c('DiscussionCard',{attrs:{"cardData":c}})],1)}),0):_vm._e()],(!_vm.isLazyLoading && _vm.getListIsLoading)?_c('div',{staticClass:"shimmer-container"},_vm._l((2),function(c){return _c('div',{key:c,staticClass:"forums__list--shimmer"},[_c('shimmer',{attrs:{"variant":"discussion-forums-post"}})],1)}),0):_c('div',{staticClass:"post__list--container",class:{
      'empty__search--container': _vm.getDiscussionPosts.results.length === 0
    }},[(_vm.getDiscussionPosts.results.length > 0)?_vm._l((_vm.getDiscussionPosts.results),function(c,index){return _c('DiscussionCard',{key:index,attrs:{"cardData":c}})}):[(_vm.searchPost || _vm.unreadFilter || _vm.dropdownValue2?.label)?_c('div',{staticClass:"search__empty--post"},[_c('div',{staticClass:"ep-image"},[_c('img',{attrs:{"src":_vm.noSearchIcon,"alt":"no search"}})]),_c('div',{staticClass:"ep-title"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty.title"))+" ")]),_c('div',{staticClass:"ep-content"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty.content"))+" ")])]):_c('div',{staticClass:"empty-post",class:{
          'hide-empty-post':
            !_vm.getTopicId && _vm.getDiscussionDraftPosts.results.length > 0
        }},[_c('div',{staticClass:"ep-image"},[_c('img',{attrs:{"src":_vm.emptyPostIcon,"alt":"no search"}})]),_c('div',{staticClass:"ep-title"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty_post.title"))+" ")]),_c('div',{staticClass:"ep-content"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty_post.content"))+" ")]),_c('div',{staticClass:"ep__info--container"},[_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":_vm.editIcon,"alt":"info"}}),_c('span',[_vm._v(_vm._s(_vm.$t("discussions.post_list.empty_post.info1.title")))])]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty_post.info1.content"))+" ")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":_vm.textIcon,"alt":"info"}}),_c('span',[_vm._v(_vm._s(_vm.$t("discussions.post_list.empty_post.info2.title")))])]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty_post.info2.content"))+" ")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":_vm.incognitoIcon,"alt":"info"}}),_c('span',[_vm._v(_vm._s(_vm.$t("discussions.post_list.empty_post.info3.title")))])]),_c('div',{staticClass:"item-content"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post_list.empty_post.info3.content"))+" ")])])]),_c('div',{staticClass:"ep-btn",attrs:{"role":"button"},on:{"click":_vm.showAddPostForm}},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.add"))+" ")])])]],2),(_vm.isLazyLoading)?_c('div',[_c('LxpLoader')],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }