<template>
  <div
    class="post__card--container"
    :class="{
      active: cardData.id === getSelectedDiscussionPost.id,
      unread: !cardData?.read && !cardData?.is_draft,
      'draft-card__container': cardData?.is_draft
    }"
    @click="onPostClick"
  >
    <div
      class="pc__banner--container"
      :class="{ 'no-banner': imageData.imageUrls.length === 0 }"
    >
      <img
        :src="imageData.imageUrls[0]"
        :alt="
          imageData.imageAltText[0] ? imageData.imageAltText[0] : 'Post Image'
        "
        v-if="imageData.imageUrls.length > 0"
      />
      <div class="pc__banner--content">
        <span
          class="pc__category--name"
          :class="{ 'close-post': cardData.closed }"
          ref="close-post-tooltip"
        >
          {{
            cardData?.is_draft
              ? $t("discussions.post.draft")
              : cardData?.topic_name
          }}
          <div class="close__post--icon" v-if="cardData.closed">
            <img :src="closedPostImage" alt="Close Post" />
            <b-tooltip
              :target="() => $refs['close-post-tooltip']"
              triggers="hover focus"
              placement="bottom"
            >
              <div class="cd__tooltip--inner">
                {{ $t("discussions.post.closed_post") }}
              </div>
            </b-tooltip>
          </div>
        </span>
        <div
          class="highlight-content"
          v-if="
            cardData?.pinned ||
              (!cardData?.is_draft && cardData?.abuse_flaggers.length > 0)
          "
        >
          <span class="pinned" ref="pin-tooltip" v-if="cardData?.pinned">
            <img :src="pinnedImage" alt="pin" />
            <b-tooltip
              :target="() => $refs['pin-tooltip']"
              triggers="hover focus"
              placement="bottom"
            >
              <div class="cd__tooltip--inner">
                {{ $t("discussions.post.pinned_tooltip") }}
              </div>
            </b-tooltip>
          </span>
          <span
            class="reported"
            v-if="!cardData?.is_draft && cardData?.abuse_flaggers.length > 0"
            ref="report-tooltip"
          >
            <img :src="reportedImage" alt="reported" />
            <b-tooltip
              :target="() => $refs['report-tooltip']"
              triggers="hover focus"
              placement="bottom"
            >
              <div class="cd__tooltip--inner">
                {{ $t("discussions.post.reported_tooltip") }}
              </div>
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>
    <div class="post__card--inner">
      <div class="post-card__date">
        {{ isPostEdited ? $t("discussions.post.edited_on") : "" }}
        {{ postUpdatedDate }}
      </div>
      <div class="post__card--title">
        {{ cardData?.title }}
      </div>
      <div class="post__card--body" v-if="imageData.imageUrls.length === 0">
        {{ renderHTML(cardData?.body) }}
      </div>
      <div class="post__details--footer">
        <div class="footer-left">
          <div class="post-user">
            <div :class="['user-image', { 'user-bg': shouldApplyUserBg }]">
              <img :src="getUserImageSrc" alt="avatar" />
            </div>
            <span>{{ getUserName }}</span>
          </div>
          <div
            class="post__author--role"
            v-if="cardData?.author_role && cardData?.author_role !== 'Learner'"
          >
            {{ $t("discussions.staff") }}
          </div>
        </div>
        <div class="footer-right" v-if="cardData?.votes">
          <img
            :src="
              cardData?.voted
                ? clapBlue
                : cardData?.votes.count > 0
                ? clapGrey
                : clapDefault
            "
            alt="like"
          />
          <span>{{ cardData?.votes.count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import moment from "moment";
export default {
  mixins: [discussionViewSelectorMixin],
  props: {
    cardData: Object
  },
  computed: {
    ...mapGetters([
      "getSelectedDiscussionPost",
      "showDiscussionTopics",
      "getAuthProfile"
    ]),
    imageData() {
      const imageUrls = [];
      const imageAltText = [];
      for (const item of this.cardData.content_list) {
        if ("image" in item) {
          imageUrls.push(item.image);
          imageAltText.push(item.alt_tag);
        }
      }
      return { imageUrls, imageAltText };
    },
    postUpdatedDate() {
      return moment(this.cardData.updated_at).format("MMM DD, YYYY");
    },
    isPostEdited() {
      return (
        new Date(this.cardData.updated_at) > new Date(this.cardData.created_at)
      );
    },
    shouldApplyUserBg() {
      return !this.cardData?.anonymous && this.getUserImage() !== null;
    },
    getUserImageSrc() {
      return this.cardData?.anonymous
        ? this.anonymousImage
        : this.getUserImage() || this.defaultAvatar;
    },
    getUserName() {
      return this.cardData?.anonymous
        ? this.$t("discussions.anonymous")
        : this.$t("dashboard.welcome_back.user_name", {
            s: this.cardData?.username
          });
    }
  },
  data() {
    return {
      clapDefault: require("@/assets/images/vle/forums/clap.svg"),
      clapGrey: require("@/assets/images/vle/forums/clap-grey.svg"),
      clapBlue: require("@/assets/images/vle/forums/clap-blue.svg"),
      pinnedImage: require("@/assets/images/vle/forums/pinned.svg"),
      reportedImage: require("@/assets/images/vle/forums/report.svg"),
      anonymousImage: require("@/assets/images/vle/anonymous.svg"),
      defaultAvatar: require("@/assets/images/menu/avatar-grey.svg"),
      closedPostImage: require("@/assets/images/vle/lock.svg")
    };
  },
  methods: {
    onPostClick() {
      if (this.cardData?.is_draft) {
        this.$store
          .dispatch("getDraftPostDetails", {
            postId: this.cardData.id
          })
          .then(() => {
            this.$store.commit(
              "SET_DISCUSSION_VIEW_SELECTOR",
              this.DISCUSSION_VIEW_SELECTOR.AddPostForm
            );
          });
        this.$router.push({
          path: `/self-paced/${this.$route.params.id}/forums/${this.cardData.id}/draft`
        });
      } else {
        this.$router
          .push({
            name: "ViewPost",
            params: { postId: this.cardData.id }
          })
          .catch(err => err);
      }
    },
    renderHTML(data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const textContent = doc.body.textContent;
      return textContent;
    },
    getUserImage() {
      return this.cardData?.is_draft
        ? this.getAuthProfile?.profile_image_url
        : this.cardData?.profile_image_url;
    }
  }
};
</script>
<style lang="scss" scoped>
.post__card--container {
  border-radius: 16px;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  background-color: $brand-neutral-0;
  padding: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
  }
  &.unread {
    border-left: 4px solid $brand-primary-400;
  }
  &:not(:first-child) {
    margin-top: 24px;
  }
  &.draft-card__container {
    background-color: $brand-neutral-75;
    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(33, 33, 33, 0.2);
    }
    .pc__banner--container {
      &.no-banner {
        + .post__card--inner {
          .post__card--title {
            margin-top: 4px !important;
            min-height: 64px;
          }
          .post__card--body {
            margin-top: 6px !important;
            min-height: 72px;
          }
        }
      }
    }
    .post__card--title {
      min-height: 55px;
    }
  }
  .pc__banner--container {
    width: 100%;
    height: 120px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .pc__banner--content {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .pc__category--name {
        border-radius: 8px;
        border: 1px solid $brand-neutral-700;
        background-color: $brand-neutral-0;
        padding: 7px 8px;
        @include body-medium;
        color: $brand-neutral-700;
        margin-right: 10px;
        &.close-post {
          color: $brand-warning;
          border-color: $brand-warning;
          background-color: $brand-warning-100;
          display: flex;
          align-items: center;
          .close__post--icon {
            margin-left: 8px;
            img {
              height: 24px;
            }
          }
        }
      }
      .highlight-content {
        display: flex;
        align-items: center;
        > *:not(:first-child) {
          margin-left: 16px;
        }
      }
      .pinned,
      .reported {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: $brand-neutral-0;
        @include flex-horizontal-center;
      }
    }
    &.no-banner {
      height: auto;
      overflow: visible;
      .pc__banner--content {
        position: relative;
        padding: 0;
      }
    }
  }
  .post__card--inner {
    margin-top: 8px;
    > *:not(:first-child) {
      margin-top: 8px;
    }
    .post-card__date {
      @include label-large($brand-neutral-300, 500);
    }
    .post__card--title {
      @include subtitle-regular;
      color: $brand-neutral-900;
      word-break: break-word;
    }
    .post__card--body {
      @include line-clamp(3);
      word-break: break-word;
    }
    .post__details--footer {
      @include horizontal-space-between;
      .footer-left {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .footer-right {
        display: flex;
        align-items: center;
        span {
          @include body-medium;
          color: $brand-neutral-700;
          margin-left: 8px;
        }
      }
    }
    .post-user {
      display: flex;
      align-items: center;
      max-width: 50%;
      .user-image {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        @include flex-horizontal-center;
        &.user-bg {
          border: 1px solid $brand-primary-400;
          img {
            height: 100%;
            object-fit: cover;
          }
        }

        img {
          width: 100%;
        }
      }

      span {
        margin-left: 8px;
        @include label-large($brand-neutral-700, 500);
      }
    }
    .post__author--role {
      @include label-large($brand-secondary1-400, 500);
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid $brand-secondary1-400;
      background-color: $brand-secondary1-50;
      margin-left: 8px;
    }
  }
}
</style>
