<template>
  <div class="right__nav--wrapper">
    <Loader v-if="isLoadingFlag" />
    <template v-else>
      <PostList
        :selectedCategoryName="selectedCategoryName"
        v-if="getDiscussionViewSelector === DISCUSSION_VIEW_SELECTOR.PostsList"
      />
      <PostDetails
        @from-view-post="showEditPost"
        @newPostAdded="$emit('newPostAdded')"
        v-else-if="
          getDiscussionViewSelector === DISCUSSION_VIEW_SELECTOR.PostDetails
        "
      />
      <DiscussionAddPost
        :isPostEditable="isPostEditable"
        @from-view-post="showEditPost"
        @newPostAdded="$emit('newPostAdded')"
        v-else-if="
          getDiscussionViewSelector === DISCUSSION_VIEW_SELECTOR.AddPostForm
        "
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiscussionAddPost from "./DiscussionAddPost.vue";
import Loader from "@/components/WrapperComponents/Loader.vue";
import PostDetails from "./PostDetails.vue";
import PostList from "./PostList.vue";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
export default {
  mixins: [discussionViewSelectorMixin],
  components: {
    PostList,
    DiscussionAddPost,
    Loader,
    PostDetails
  },
  props: {
    isLoadingFlag: Boolean,
    selectedCategoryName: Object
  },
  computed: {
    ...mapGetters([
      "getSelectedCourse",
      "getDiscussionViewSelector",
      "getSelectedDiscussionPost",
      "language"
    ])
  },
  data() {
    return {
      isPostEditable: false
    };
  },
  methods: {
    showEditPost(value) {
      this.isPostEditable = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.right__nav--wrapper {
  width: 100%;
  text-align: initial;
  .discussion__sec--content {
    text-align: left;
    margin: 0 10% 0 15%;
    .discussion__sec--head {
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: 0.1px;
      color: $brand-secondary-text;
    }
    .discussion-body {
      border: solid 1px;
      border-color: #e8e8e8 transparent #e8e8e8 transparent;
      padding-bottom: 50px;

      .body-head {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: $vle-primary-text;
        font-weight: 500;
      }
      .body-text {
        @include label-medium;
        line-height: 50px;
        color: $vle-primary-text;
      }
      .body-card {
        display: grid;
      }
    }
  }
}
</style>
