var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post__card--container",class:{
    active: _vm.cardData.id === _vm.getSelectedDiscussionPost.id,
    unread: !_vm.cardData?.read && !_vm.cardData?.is_draft,
    'draft-card__container': _vm.cardData?.is_draft
  },on:{"click":_vm.onPostClick}},[_c('div',{staticClass:"pc__banner--container",class:{ 'no-banner': _vm.imageData.imageUrls.length === 0 }},[(_vm.imageData.imageUrls.length > 0)?_c('img',{attrs:{"src":_vm.imageData.imageUrls[0],"alt":_vm.imageData.imageAltText[0] ? _vm.imageData.imageAltText[0] : 'Post Image'}}):_vm._e(),_c('div',{staticClass:"pc__banner--content"},[_c('span',{ref:"close-post-tooltip",staticClass:"pc__category--name",class:{ 'close-post': _vm.cardData.closed }},[_vm._v(" "+_vm._s(_vm.cardData?.is_draft ? _vm.$t("discussions.post.draft") : _vm.cardData?.topic_name)+" "),(_vm.cardData.closed)?_c('div',{staticClass:"close__post--icon"},[_c('img',{attrs:{"src":_vm.closedPostImage,"alt":"Close Post"}}),_c('b-tooltip',{attrs:{"target":() => _vm.$refs['close-post-tooltip'],"triggers":"hover focus","placement":"bottom"}},[_c('div',{staticClass:"cd__tooltip--inner"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.closed_post"))+" ")])])],1):_vm._e()]),(
          _vm.cardData?.pinned ||
            (!_vm.cardData?.is_draft && _vm.cardData?.abuse_flaggers.length > 0)
        )?_c('div',{staticClass:"highlight-content"},[(_vm.cardData?.pinned)?_c('span',{ref:"pin-tooltip",staticClass:"pinned"},[_c('img',{attrs:{"src":_vm.pinnedImage,"alt":"pin"}}),_c('b-tooltip',{attrs:{"target":() => _vm.$refs['pin-tooltip'],"triggers":"hover focus","placement":"bottom"}},[_c('div',{staticClass:"cd__tooltip--inner"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.pinned_tooltip"))+" ")])])],1):_vm._e(),(!_vm.cardData?.is_draft && _vm.cardData?.abuse_flaggers.length > 0)?_c('span',{ref:"report-tooltip",staticClass:"reported"},[_c('img',{attrs:{"src":_vm.reportedImage,"alt":"reported"}}),_c('b-tooltip',{attrs:{"target":() => _vm.$refs['report-tooltip'],"triggers":"hover focus","placement":"bottom"}},[_c('div',{staticClass:"cd__tooltip--inner"},[_vm._v(" "+_vm._s(_vm.$t("discussions.post.reported_tooltip"))+" ")])])],1):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"post__card--inner"},[_c('div',{staticClass:"post-card__date"},[_vm._v(" "+_vm._s(_vm.isPostEdited ? _vm.$t("discussions.post.edited_on") : "")+" "+_vm._s(_vm.postUpdatedDate)+" ")]),_c('div',{staticClass:"post__card--title"},[_vm._v(" "+_vm._s(_vm.cardData?.title)+" ")]),(_vm.imageData.imageUrls.length === 0)?_c('div',{staticClass:"post__card--body"},[_vm._v(" "+_vm._s(_vm.renderHTML(_vm.cardData?.body))+" ")]):_vm._e(),_c('div',{staticClass:"post__details--footer"},[_c('div',{staticClass:"footer-left"},[_c('div',{staticClass:"post-user"},[_c('div',{class:['user-image', { 'user-bg': _vm.shouldApplyUserBg }]},[_c('img',{attrs:{"src":_vm.getUserImageSrc,"alt":"avatar"}})]),_c('span',[_vm._v(_vm._s(_vm.getUserName))])]),(_vm.cardData?.author_role && _vm.cardData?.author_role !== 'Learner')?_c('div',{staticClass:"post__author--role"},[_vm._v(" "+_vm._s(_vm.$t("discussions.staff"))+" ")]):_vm._e()]),(_vm.cardData?.votes)?_c('div',{staticClass:"footer-right"},[_c('img',{attrs:{"src":_vm.cardData?.voted
              ? _vm.clapBlue
              : _vm.cardData?.votes.count > 0
              ? _vm.clapGrey
              : _vm.clapDefault,"alt":"like"}}),_c('span',[_vm._v(_vm._s(_vm.cardData?.votes.count))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }